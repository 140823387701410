@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';


.ant-form-item-label>label.ant-form-item-required::before {
  content: none !important;
}

.ant-form-item-label>label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: '*';
}
@primary-color: #14B5D9;@link-color: #14B5D9;@border-radius-base: 4px;